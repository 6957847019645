import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Container from '@/components/Container';
import ImageTile from '@/components/ImageTile';
import Link from '@/components/Link';
import TileWrapper from '@/components/TileWrapper';

const Team: React.FC = () => (
  <Container>
    <TileWrapper>
      <ImageTile aspect="extrawide">
        <Link to="https://www.phocuswire.com/hot-25-travel-startups-2025-ancillarybox">
          <StaticImage
            src="../../resources/images/hot-25.jpg"
            alt="Hot 25 Travel Startups for 2025 Banner"
          />
        </Link>
      </ImageTile>
    </TileWrapper>
  </Container>
);

export default Team;
