import React from 'react';
import { PageProps } from 'gatsby';
import Banner from '@/blocks/Banner';
import Consulting from '@/blocks/Consulting';
import Intro from '@/blocks/Intro';
import Products from '@/blocks/Products';
import Team from '@/blocks/Team';
import Seo from '@/components/Seo';
import Wrapper from '@/layouts/wrapper';

const IndexPage: React.FC<PageProps> = () => (
  <Wrapper>
    <Seo title="Accelerating Airlines Ancillary Journey" />
    <Intro />
    <Products />
    <Consulting />
    <Team />
    <Banner />
  </Wrapper>
);

export default IndexPage;
