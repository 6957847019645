import React, { useCallback } from 'react';
import { m as motion, MotionProps } from 'framer-motion';

export interface TileProps extends React.HTMLAttributes<HTMLDivElement> {
  theme?: 'light' | 'dark' | 'color';
  accent?: Accent;
  aspect?: 'square' | 'wide' | 'extrawide';
  delay?: number;
  innerClassName?: string;
}

const Tile: React.FC<TileProps & MotionProps> = ({
  theme,
  accent,
  aspect,
  delay,
  className,
  innerClassName,
  children,
  ...any
}) => {
  const getBaseClassName = useCallback(
    (theme: NonNullable<TileProps['theme']>) =>
      ({
        light: 'bg-white text-gray-800',
        dark: 'bg-gray-800 text-white',
        color:
          accent === 'yellow'
            ? 'bg-yellow-900 text-white'
            : 'bg-primary-900 text-white',
      }[theme]),
    [accent],
  );

  const getAspectClassName = useCallback(
    (aspect: NonNullable<TileProps['aspect']>) =>
      ({
        square: 'pt-[100%]',
        wide: 'md:pt-[50%]',
        extrawide: 'md:pt-[33.3333%]',
      }[aspect]),
    [],
  );

  const baseClassName =
    theme && !className?.match(/(?:^|\s)bg-/i) ? getBaseClassName(theme) : '';

  return (
    <motion.div
      variants={{
        hidden: { opacity: 0, y: '10%' },
        visible: {
          y: '0%',
          opacity: 1,
          transition: {
            ease: 'easeOut',
            duration: 0.7,
            delayChildren: 0.25 + (delay ?? 0),
            staggerChildren: 0.25,
          },
        },
      }}
      className={`${baseClassName} relative shadow-xl ${className ?? ''}`}
      {...any}
    >
      <motion.div
        className="flex items-center justify-center w-full h-full"
        whileInView="visible"
        initial="hidden"
        viewport={{ once: true }}
        variants={{
          hidden: {},
          visible: {
            transition: {
              ease: 'easeOut',
              duration: 0.7,
              delayChildren: 0.25 + (delay ?? 0),
              staggerChildren: 0.25,
            },
          },
        }}
      >
        {aspect && (
          <div className={`float-left ${getAspectClassName(aspect)}`} />
        )}
        <div className={innerClassName}>{children}</div>
        <div className="clear-both" />
      </motion.div>
    </motion.div>
  );
};

Tile.defaultProps = {
  theme: 'light',
  accent: 'purple',
  aspect: 'square',
  delay: 0,
  innerClassName: undefined,
};

export default Tile;
